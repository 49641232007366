import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

// components
import InputOtp from "components/Auth/InputOtp";

// assets
import logo from "assets/images/logo.png";
import sitting from "assets/images/sitting.svg";
import ic_hand_left_black from "assets/icons/ic_hand_left_black.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  ForgotPasswordUser,
  resetMsg,
  resetSuccess,
  CheckOtp,
} from "features/authSlice";
import { toast } from "react-hot-toast";

const Verification = () => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (message) toast.error(message);
    dispatch(resetMsg());
  }, [message, dispatch]);

  const verificationOtp = (value) => {
    dispatch(CheckOtp(`FKG-${value}`)).then((response) => {
      if (!response.error) {
        navigate(
          ```/reset-password?email=${searchParams.get("email")}&otp=${value}`
        );
      } else {
        toast.error(response.payload);
      }
    });
  };

  const resendOtp = () => {
    dispatch(ForgotPasswordUser(searchParams.get("email")));
    dispatch(resetSuccess());
    toast.success(
      <span>
        Kode OTP berhasil dikirim!
        <i className="text-en">OTP code sent successfully!</i>
      </span>
    );
  };

  return (
    <div className="auth-wrap flex flex-wrap">
      <div className="mt-5 w-full px-5 xl:px-14">
        <Link to="/">
          <img src={logo} alt="FKG Unpad" />
        </Link>

        {/* Form */}
        <form className="form-card">
          <h1 className="text-xl font-semibold lg:text-4xl">
            Verifikasi Kode OTP
            <i className="text-en">OTP Code Verification</i>
          </h1>
          <h4 className="text-base text-accent-gray lg:text-xl">
            Silahkan masukan kode OTP yang kami kirimkan melalui email.
            <i className="text-en">
              Please enter the OTP code that we sent via email.
            </i>
          </h4>

          <div className="mt-20 flex justify-center">
            <InputOtp
              value={otp}
              onChange={(value) => setOtp(value)}
              onComplete={(value) => verificationOtp(value)}
              onResend={() => resendOtp()}
            />
          </div>
        </form>
      </div>

      <div className="absolute bottom-0 right-0 -z-10 hidden w-[500px] lg:block">
        <img src={sitting} alt="sitting" />
      </div>

      <div
        onClick={() => navigate(-1)}
        className="fixed bottom-10 left-10 flex cursor-pointer items-center"
      >
        <img src={ic_hand_left_black} alt="Icon Hand Left" className="mr-2" />
        <div>
          Kembali <i className="text-en">Back</i>
        </div>
      </div>
    </div>
  );
};

export default Verification;
