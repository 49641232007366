import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// language
import languages from "languages/id_en";

// services
import {
  getStudentRequirementById,
  updateStudentProgressReport,
  getPatientTypodont,
  updatePatientTypodont,
} from "services/Requirement/Dosen";
import {
  confirmModal,
  getStatus,
  getStatusEn,
  successModal,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import Footer from "components/Footer";

import InputSearch from "components/Form/InputSearch";
import InputSelect from "components/Form/InputSelect";
import InputToggleBig from "components/Form/InputToggleBig";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import InputToggle from "components/Form/InputToggle";
import Modal from "components/Requirement/Modal";

// assets
import ic_search from "assets/icons/ic_search.svg";
import ic_eye_green from "assets/icons/ic_eye_green.svg";
import ic_pencil_yellow from "assets/icons/ic_pencil_yellow.svg";
import NotFound from "components/NotFound";

const PasienTypodont = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [progress, setProgress] = useState({
    id: "",
    unfinish: false,
    process: false,
    finish: false,
  });

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalTitleEn, setModalTitleEn] = useState("");
  const [modalData, setModalData] = useState({
    id: "",
    unready: false,
    process: false,
    finish: false,
  });

  const [search, setSearch] = useState("");

  const openModal = (name, name_en, data) => {
    setModalTitle(name);
    setModalTitleEn(name_en);
    setModalData(data);
    setShowModal(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseProgress = await getStudentRequirementById(
        searchParams.get("rqm-id"),
        searchParams.get("id")
      );
      const response = await getPatientTypodont(searchParams.get("id"));

      setProgress(responseProgress.data.body.data.requirement);
      setData(response.data.body.data.patient);
    } catch (error) {
      console.error(error);
    }
  };

  const setProgressData = (key, value) => {
    const newProgressData = { ...progress };
    newProgressData[key] = value;

    // setProgress(newProgressData);

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateStudentProgressReport(
            searchParams.get("rpt-id"),
            newProgressData
          );

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const setModal = (key, value) => {
    const newModalData = { ...modalData };
    newModalData[key] = value;

    setModalData(newModalData);
  };

  const submit = (e) => {
    e.preventDefault();
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updatePatientTypodont(modalData.id, modalData);

          getData();
          successModal(
            "Success",
            "Data berhasil disimpan! <i class='text-en'>Data successfully saved</i>"
          );
          setShowModal(false);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <div>
          <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
            <InputToggleBig
              name={
                <>
                  Belum <i className="text-en">Incomplete</i>
                </>
              }
              enabled={progress.unfinish}
              onChange={(value) => setProgressData("unfinish", value)}
            />
            <InputToggleBig
              name={
                <>
                  Proses <i className="text-en">Process</i>
                </>
              }
              enabled={progress.process}
              onChange={(value) => setProgressData("process", value)}
            />
            <InputToggleBig
              name="100%"
              enabled={progress.finish}
              onChange={(value) => setProgressData("finish", value)}
            />
          </div>

          {/* Filter */}
          <div className="mt-7 flex flex-wrap gap-5 md:w-3/4 md:flex-nowrap lg:w-1/2">
            <InputSearch
              name="search"
              value={search}
              required={false}
              placeholder="Search pasien / patient.."
              prepend={<img src={ic_search} alt="Icon search" />}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="mt-5 overflow-x-scroll md:overflow-visible">
            {data.length ? (
              <div className="grid w-max grid-cols-2 gap-4 md:w-full">
                {data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="grid grid-cols-2 justify-between gap-5 rounded-[20px] bg-white px-4 py-5 md:flex"
                  >
                    {/* Title */}
                    <div>
                      <h3 className="text-xl font-semibold">{value.name}</h3>
                      <span className="text-base text-accent-gray">
                        Pasien ke {index + 1} | {value.gender}
                        <i className="text-en">
                          Patient to {index + 1} | {languages[value.gender]}
                        </i>
                      </span>

                      {value.finish ? (
                        <div className="mt-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-2 font-medium leading-[18px]">
                          Sudah Divalidasi
                          <i className="text-en">Validated</i>
                        </div>
                      ) : value.process ? (
                        <div className="mt-3 rounded-[10px] bg-[#F4AE0040] px-4 py-2 font-medium leading-[18px]">
                          Sedang Divalidasi
                          <i className="text-en">Currently Validated</i>
                        </div>
                      ) : (
                        <div className="mt-3 rounded-[10px] bg-[#F3787840] px-4 py-2 font-medium leading-[18px]">
                          Belum Divalidasi
                          <i className="text-en">Not Validated yet</i>
                        </div>
                      )}
                    </div>

                    <div>
                      {value.finish ? (
                        <div
                          onClick={() =>
                            navigate(
                              `detail?no=${index + 1}&id=${
                                value.id
                              }&name=${searchParams.get(
                                "name"
                              )}&npm=${searchParams.get(
                                "npm"
                              )}&avatar=${searchParams.get("avatar")}`
                            )
                          }
                          className="mb-4 flex cursor-pointer items-center justify-between gap-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-3 font-medium text-accent-gray transition duration-300 hover:opacity-80"
                        >
                          Detail
                          <img src={ic_eye_green} alt="Icon Eye" />
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            navigate(
                              `detail?no=${index + 1}&id=${
                                value.id
                              }&name=${searchParams.get(
                                "name"
                              )}&npm=${searchParams.get(
                                "npm"
                              )}&avatar=${searchParams.get("avatar")}`
                            )
                          }
                          className="mb-4 flex cursor-pointer items-center justify-between gap-3 rounded-[10px] bg-[#F4AE001A] px-4 py-3 font-medium text-accent-gray transition duration-300 hover:opacity-80"
                        >
                          <span>
                            Validasi <i className="text-en">Validation</i>
                          </span>
                          <img src={ic_pencil_yellow} alt="Icon Pencil" />
                        </div>
                      )}

                      {value.finish ? (
                        <div className="flex items-center justify-between gap-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-3 font-medium text-accent-gray">
                          <span>
                            {getStatus(value)}{" "}
                            <i className="text-en">{getStatusEn(value)}</i>
                          </span>
                          <span className="h-[20px] w-[20px] rounded-full bg-green"></span>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            openModal(
                              "Pasien Typodont",
                              "Typodont patient",
                              value
                            )
                          }
                          className="mb-4 flex cursor-pointer items-center justify-between gap-3 rounded-[10px] bg-[#F4AE001A] px-4 py-3 font-medium text-accent-gray transition duration-300 hover:opacity-80"
                        >
                          <span>
                            Kesimpulan <i className="text-en">Conclusion</i>
                          </span>
                          <img src={ic_pencil_yellow} alt="Icon Pencil" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <NotFound />
            )}
          </div>
        </div>

        <Footer />
      </TFade>
      <Modal
        name={`Validasi ${modalTitle}`}
        name_en={`${modalTitleEn} Validation`}
        textSubmit="Validasi"
        textSubmitEn="Validation"
        isSave
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        onSubmit={submit}
        form={
          <>
            <div className="mb-6 flex gap-5 self-center">
              <div
                className={`relative z-0 block  w-1/2 cursor-pointer rounded-[10px] border-2 border-transparent bg-[#D9D9D933] px-2.5 pt-5 pb-2 text-base font-medium text-black transition`}
              >
                {modalData.name}
                <label
                  className={`absolute top-2.5 left-2.5 -z-10 origin-[0] -translate-y-2 scale-75 transform select-none text-base text-accent-gray duration-300`}
                >
                  {modalTitle} / <i>{modalTitleEn}</i>
                </label>
              </div>
            </div>
            <div className="grid gap-x-5 lg:grid-cols-3">
              <div>
                <InputToggle
                  name={
                    <>
                      Belum / <i>Incomplete</i>
                    </>
                  }
                  enabled={modalData.unready}
                  onChange={(value) => setModal("unready", value)}
                />
              </div>

              <div>
                <InputToggle
                  name={
                    <>
                      Proses / <i>Process</i>
                    </>
                  }
                  enabled={modalData.process}
                  onChange={(value) => setModal("process", value)}
                />
              </div>

              <div>
                <InputToggle
                  name={
                    <>
                      Sudah / <i>Complete</i>
                    </>
                  }
                  enabled={modalData.finish}
                  onChange={(value) => setModal("finish", value)}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default PasienTypodont;
