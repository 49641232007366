import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// language
import languages from "languages/id_en";

// services
import {
  getStudentRequirementById,
  getPatientClinicContinue,
  exportMaterialsPatient,
  exportToolsPatient,
  exportJawUndersPatient,
  exportJawUppersPatient,
} from "services/Requirement/Prodi";
import { getStatus, getStatusEn } from "services/general";

// components
import TFade from "components/Transition/Fade";
import HeaderRequirement from "components/Requirement/HeaderRequirement";
import Footer from "components/Footer";

import InputSearch from "components/Form/InputSearch";
import InputSelect from "components/Form/InputSelect";
import InputExport from "components/Requirement/InputExport";
import InputStatusBig from "components/Requirement/InputStatusBig";

// assets
import ic_search from "assets/icons/ic_search.svg";
import ic_eye_green from "assets/icons/ic_eye_green.svg";
import ic_download from "assets/icons/ic_download.svg";
import NotFound from "components/NotFound";

const PasienKlinikLanjutan = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [progress, setProgress] = useState({
    id: "",
    unfinish: false,
    process: false,
    finish: false,
  });

  const [search, setSearch] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const responseProgress = await getStudentRequirementById(
        searchParams.get("rqm-id"),
        searchParams.get("id")
      );
      const response = await getPatientClinicContinue(searchParams.get("id"));

      setProgress(responseProgress.data.body.data.requirement);
      setData(response.data.body.data.patient);
    } catch (error) {
      console.error(error);
    }
  };

  const exportMaterial = async () => {
    const response = await exportMaterialsPatient(
      searchParams.get("rqm-id"),
      searchParams.get("id")
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${response.data.body.data.file}`,
      "_blank"
    );
  };

  const exportTool = async () => {
    const response = await exportToolsPatient(
      searchParams.get("rqm-id"),
      searchParams.get("id")
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${response.data.body.data.file}`,
      "_blank"
    );
  };

  const exportJaw = async () => {
    const responseUnders = await exportJawUndersPatient(
      searchParams.get("rqm-id"),
      searchParams.get("id")
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${responseUnders.data.body.data.file}`,
      "_blank"
    );

    const responseUppers = await exportJawUppersPatient(
      searchParams.get("rqm-id"),
      searchParams.get("id")
    );
    window.open(
      `${process.env.REACT_APP_STORAGE}excels/${responseUppers.data.body.data.file}`,
      "_blank"
    );
  };

  return (
    <>
      <TFade>
        <HeaderRequirement />

        <div>
          <InputExport className="mb-5" />

          <div className="mb-5 grid grid-cols-1 gap-5 md:grid-cols-3">
            <InputExport
              text="Pengambilan Bahan"
              textEn="Material Pickup"
              isExport={false}
              onClick={exportMaterial}
            />
            <InputExport
              text="Jenis Peranti Ortodonti"
              textEn="Type of orthodontics appliance"
              isExport={false}
              onClick={exportTool}
            />
            <InputExport
              text="Aktivasi Rahang"
              textEn="Upper & Lower Activation"
              isExport={false}
              onClick={exportJaw}
            />
          </div>

          <div className="mb-10 grid grid-cols-1 gap-5 md:grid-cols-3">
            <InputStatusBig
              name={
                <>
                  Belum <i className="text-en">Incomplete</i>
                </>
              }
              enabled={progress.unfinish}
            />
            <InputStatusBig
              name={
                <>
                  Proses <i className="text-en">Process</i>
                </>
              }
              enabled={progress.process}
            />
            <InputStatusBig name="100%" enabled={progress.finish} />
          </div>

          {/* Filter */}
          <div className="mt-7 flex flex-wrap gap-5 md:w-3/4 md:flex-nowrap lg:w-1/2">
            <InputSearch
              name="search"
              value={search}
              required={false}
              placeholder="Search pasien / patient.."
              prepend={<img src={ic_search} alt="Icon search" />}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="mt-5 overflow-x-scroll md:overflow-visible">
            {data.length ? (
              <div className="grid w-max grid-cols-2 gap-4 md:w-full">
                {data.map((value, index) => (
                  <div
                    key={`data-${index}`}
                    className="grid grid-cols-2 justify-between gap-5 rounded-[20px] bg-white px-4 py-5 md:flex"
                  >
                    {/* Title */}
                    <div>
                      <h3 className="text-xl font-semibold">{value.name}</h3>
                      <span className="text-base text-accent-gray">
                        Pasien ke {index + 1} | {value.gender}
                        <i className="text-en">
                          Patient to {index + 1} | {languages[value.gender]}
                        </i>
                      </span>

                      {value.finish ? (
                        <div className="mt-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-2 font-medium leading-[18px]">
                          Sudah Divalidasi
                          <i className="text-en">Validated</i>
                        </div>
                      ) : value.process ? (
                        <div className="mt-3 rounded-[10px] bg-[#F4AE0040] px-4 py-2 font-medium leading-[18px]">
                          Sedang Divalidasi
                          <i className="text-en">Currently Validated</i>
                        </div>
                      ) : (
                        <div className="mt-3 rounded-[10px] bg-[#F3787840] px-4 py-2 font-medium leading-[18px]">
                          Belum Divalidasi
                          <i className="text-en">Not Validated yet</i>
                        </div>
                      )}
                    </div>

                    <div>
                      <div
                        onClick={() =>
                          navigate(
                            `detail?no=${index + 1}&id=${
                              value.id
                            }&name=${searchParams.get(
                              "name"
                            )}&npm=${searchParams.get(
                              "npm"
                            )}&avatar=${searchParams.get("avatar")}`
                          )
                        }
                        className="mb-4 flex cursor-pointer items-center justify-between gap-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-3 font-medium text-accent-gray transition duration-300 hover:opacity-80"
                      >
                        Detail
                        <img src={ic_eye_green} alt="Icon Eye" />
                      </div>

                      {value.finish && (
                        <>
                          <div
                            onClick={() => {}}
                            className="mb-4 flex cursor-pointer items-center justify-between gap-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-3 font-medium text-accent-gray transition duration-300 hover:opacity-80"
                          >
                            <span>
                              Unduh <i className="text-en">Download</i>
                            </span>
                            <img src={ic_download} alt="Icon Download" />
                          </div>

                          <div className="flex items-center justify-between gap-3 rounded-[10px] bg-[#59CE8F1A] px-4 py-3 font-medium text-accent-gray">
                            <span>
                              {getStatus(value)}{" "}
                              <i className="text-en">{getStatusEn(value)}</i>
                            </span>
                            <span className="h-[20px] w-[20px] rounded-full bg-green"></span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <NotFound />
            )}
          </div>
        </div>

        <Footer />
      </TFade>
    </>
  );
};

export default PasienKlinikLanjutan;
