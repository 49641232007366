import { useEffect, useState } from "react";
import TFade from "components/Transition/Fade";
import propTypes from "prop-types";

// components
import InputText from "components/Auth/InputText";
import InputPassword from "components/Auth/InputPassword";
import InputFormat from "components/Auth/InputFormat";

// assets
import ic_arrow_right_black from "assets/icons/ic_arrow_right_black.svg";
import { Fade } from "react-awesome-reveal";

const PersonalData = (props) => {
  let { form } = props;

  const [fullName, setFullName] = useState("");
  const [npm, setNpm] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setFullName(form.fullName);
    setNpm(form.npm);
    setPhoneNumber(form.phoneNumber);
    setEmail(form.email);
    setPassword(form.password);
  }, [form]);

  const submit = (e) => {
    e.preventDefault();

    form = {
      ...form,
      fullName,
      npm,
      phoneNumber,
      email,
      password,
    };

    props.updateForm(form);
  };

  const checkForm = () => {
    if (
      fullName == "" ||
      npm == "" ||
      phoneNumber == "" ||
      email == "" ||
      password == ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fade duration={750} className="w-full px-5 xl:px-14">
      {/* Form */}
      <form onSubmit={submit}>
        <h1 className="mb-5 text-center text-3xl font-semibold text-accent-black lg:hidden">
          Isi Data Diri
          <i className="text-en">Fill in Personal Data</i>
        </h1>

        <InputText
          name="fullName"
          type="text"
          placeholder={
            <>
              Nama Lengkap / <i>Full Name</i>
            </>
          }
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          onDelete={() => setFullName("")}
        />

        <InputFormat
          name="npm"
          placeholder={
            <>
              NPM / <i>Registration student number</i>
            </>
          }
          inputClassName="mt-5"
          template="xxxx - xx - xx - xx - xx"
          value={npm}
          onChange={(value) => setNpm(value)}
          onDelete={() => setNpm("")}
        />

        <InputFormat
          name="phoneNumber"
          placeholder={
            <>
              Nomor Handphone / <i>Phone Number</i>
            </>
          }
          inputClassName="mt-5"
          prepend="+62"
          template="xxx - xxxx - xxxx"
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value)}
          onDelete={() => setPhoneNumber("")}
        />

        <InputText
          name="email"
          type="email"
          placeholder="Email"
          inputClassName="mt-5"
          hint={
            <>
              Catatan: Gunakan email yang diberikan kampus.
              <i className="text-en">
                Note: Use the email provided by the campus.
              </i>
            </>
          }
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onDelete={() => setEmail("")}
        />

        <InputPassword
          name="password"
          placeholder="Password"
          inputClassName="mt-5"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div
          className={`mt-5 flex justify-end transition ${
            checkForm() && "opacity-[.65]"
          }`}
        >
          <button type="submit" className="flex items-center">
            <h2 className="text-lg font-medium md:text-xl">
              Lanjut
              <i className="text-en">Next</i>
            </h2>
            <div className="icon-arrow ml-3 border-black">
              <img src={ic_arrow_right_black} alt="Icon Arrow Right" />
            </div>
          </button>
        </div>
      </form>
    </Fade>
  );
};

export default PersonalData;

PersonalData.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
};
